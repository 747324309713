const memberLogin = document.querySelector('#member')
const applicantLogin = document.querySelector('#applicant')
const tabLinks = document.querySelector('#login-select').querySelectorAll('a')

document.addEventListener('DOMContentLoaded', () =>{
  if(window.location.hash == '#member'){
    tabLinks[0].classList.add('active')
    tabLinks[1].classList.remove('active')
    memberLogin.style.display = 'block'
    applicantLogin.style.display = 'none'
  }else if(window.location.hash == '#applicant'){
    tabLinks[0].classList.remove('active')
    tabLinks[1].classList.add('active')
    applicantLogin.style.display = 'block'
    memberLogin.style.display = 'none'
  }
})

tabLinks[0].addEventListener('click', (e) => {
  memberLogin.style.display = 'block'
  applicantLogin.style.display = 'none'
})

tabLinks[1].addEventListener('click', (e) => {
  e.preventDefault()
  memberLogin.style.display = 'none'
  applicantLogin.style.display = 'block'
})
tabLinks.forEach((element) => {
  element.addEventListener('click', (e) => {
    e.preventDefault()
    tabLinks.forEach((element) =>{
      element.classList.toggle('active')
    })
  })
})
